<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Customer</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    label="Full Name"
                    placeholder="John Doe"
                    :rules="nameRules"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('name')"
                    class="validation-error"
                  >{{ form.errors.get('name') }}</small>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.phone"
                    label="Phone number"
                    placeholder="07******"
                    :rules="phoneRules"
                    required
                  ></v-text-field>

                  <small
                    v-show="form.errors.has('phone')"
                    class="validation-error"
                  >{{
                    form.errors.get('phone')
                  }}</small>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    ref="email"
                    v-model="form.email"
                    label="Email (Optional)"
                    placeholder="example@domain.com"
                    :rules="optionalEmailRules"
                  ></v-text-field>

                  <small
                    v-show="form.errors.has('email')"
                    class="validation-error"
                  >{{
                    form.errors.get('email')
                  }}</small>
                </v-col>

                <v-col cols="8">
                  <v-text-field
                    v-model="form.account_number"
                    label="Account Number"
                    placeholder="Account number for this customer"
                    hint="Will be used during payments"
                    :rules="accountNumberRules"
                  ></v-text-field>

                  <small
                    v-show="form.errors.has('account_number')"
                    class="validation-error"
                  >{{
                    form.errors.get('account_number')
                  }}</small>
                </v-col>

                <v-col
                  cols="12"
                  sm="8"
                >
                  <v-autocomplete
                    :value="form.meter_id"
                    :items="meters"
                    item-text="number"
                    item-value="id"
                    :loading="isMetersLoading"
                    :search-input.sync="meters_search"
                    placeholder="Type meter number to get suggestions"
                    label="Meter Number"
                    :rules="[v => !!v || 'Meter number is required']"
                    hide-no-data
                    required
                    return-object
                    @change="onSelectedMeterChange"
                  ></v-autocomplete>

                  <small
                    v-show="form.errors.has('meter_id')"
                    class="validation-error"
                  >{{
                    form.errors.get('meter_id')
                  }}</small>
                </v-col>

                <v-expand-transition>
                  <v-col
                    v-show="action === 'Save'"
                    cols="8"
                  >
                    <v-text-field
                      v-model="form.credit"
                      label="Credit"
                      placeholder="Credit that customer may have previously"
                      :rules="numberRulesAllowZero"
                    ></v-text-field>

                    <small
                      v-show="form.errors.has('credit')"
                      class="validation-error"
                    >{{
                      form.errors.get('credit')
                    }}</small>
                  </v-col>
                </v-expand-transition>

                <v-expand-transition>
                  <v-col
                    v-show="action === 'Save'"
                    cols="8"
                  >
                    <v-text-field
                      v-model="form.debt"
                      label="Debt"
                      placeholder="Debt that customer may have previously"
                      :rules="numberRulesAllowZero"
                    ></v-text-field>

                    <small
                      v-show="form.errors.has('debt')"
                      class="validation-error"
                    >{{
                      form.errors.get('debt')
                    }}</small>
                  </v-col>
                </v-expand-transition>

                <v-col
                  cols="8"
                  class="mb-0 pb-0"
                >
                  <v-checkbox
                    v-model="form.use_custom_charges_for_cost_per_unit"
                    label="Use custom charges for cost per unit"
                    :false-value="0"
                    :true-value="1"
                  ></v-checkbox>

                  <small
                    v-show="form.errors.has('use_custom_charges_for_cost_per_unit')"
                    class="validation-error"
                  >{{
                    form.errors.get('use_custom_charges_for_cost_per_unit')
                  }}</small>
                </v-col>
                <v-expand-transition>
                  <v-col
                    v-show="form.use_custom_charges_for_cost_per_unit"
                    cols="6"
                    class="mt-0 pb-0 ml-8"
                  >
                    <v-text-field
                      v-model="form.cost_per_unit"
                      label="Cost per unit (in Ksh)"
                      placeholder="120"
                      :rules="optionalNumberRules"
                      :required="form.use_custom_charges_for_cost_per_unit"
                    ></v-text-field>
                    <small
                      v-show="form.errors.has('cost_per_unit')"
                      class="validation-error"
                    >{{
                      form.errors.get('cost_per_unit')
                    }}</small>
                  </v-col>
                </v-expand-transition>
                <v-col
                  cols="8"
                  class="mb-0 pb-0"
                >
                  <v-checkbox
                    v-model="form.use_custom_charges_for_service_charge"
                    label="Use custom charges for service charge"
                    :false-value="0"
                    :true-value="1"
                  ></v-checkbox>

                  <small
                    v-show="form.errors.has('use_custom_charges_for_service_charge')"
                    class="validation-error"
                  >{{
                    form.errors.get('use_custom_charges_for_service_charge')
                  }}</small>
                </v-col>
                <v-expand-transition>
                  <v-col
                    v-show="form.use_custom_charges_for_service_charge"
                    cols="6"
                    class="mt-0 pb-0 ml-8"
                  >
                    <v-text-field
                      v-model="form.service_charge"
                      label="Service charge (in Ksh)"
                      placeholder="200"
                      :rules="optionalNumberRules"
                      :required="form.use_custom_charges_for_service_charge"
                    ></v-text-field>
                    <small
                      v-show="form.errors.has('service_charge')"
                      class="validation-error"
                    >{{
                      form.errors.get('service_charge')
                    }}</small>
                  </v-col>
                </v-expand-transition>
                <v-col
                  cols="8"
                  class="mb-0 pb-0"
                >
                  <v-checkbox
                    v-model="form.should_pay_connection_fee"
                    :false-value="0"
                    :true-value="1"
                    label="Should pay for meter connection fee"
                  ></v-checkbox>

                  <small
                    v-show="form.errors.has('should_pay_connection_fee')"
                    class="validation-error"
                  >{{
                    form.errors.get('should_pay_connection_fee')
                  }}</small>
                </v-col>
                <v-expand-transition>
                  <div v-show="form.should_pay_connection_fee">
                    <v-col
                      cols="12"
                      class="mt-0 pb-0 ml-8"
                    >
                      <v-text-field
                        v-model="form.connection_fee"
                        label="Connection fee (in Ksh)"
                        placeholder="5000"
                        :rules="optionalNumberRules"
                      ></v-text-field>

                      <small
                        v-show="form.errors.has('connection_fee')"
                        class="validation-error"
                      >{{
                        form.errors.get('connection_fee')
                      }}</small>
                    </v-col>
                    <v-col
                      cols="12"
                      class="mt-0 pb-0 ml-8"
                    >
                      <v-text-field
                        v-model="form.number_of_months_to_pay_connection_fee"
                        label="Number of months to pay"
                        placeholder="2"
                        :rules="optionalNumberRules"
                      ></v-text-field>

                      <small
                        v-show="form.errors.has('number_of_months_to_pay_connection_fee')"
                        class="validation-error"
                      >{{
                        form.errors.get('number_of_months_to_pay_connection_fee')
                      }}</small>
                    </v-col>
                    <v-col
                      cols="8"
                      class="mt-0 pb-0 ml-8"
                    >
                      <v-menu
                        ref="month_menu"
                        v-model="month_menu"
                        :close-on-content-click="false"
                        :return-value.sync="form.first_connection_fee_on"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.first_connection_fee_on"
                            label="First connection fee on"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.first_connection_fee_on"
                          no-title
                          scrollable
                          @change="
                            $refs.month_menu.save(form.first_connection_fee_on)
                            month_menu = false
                          "
                        >
                        </v-date-picker>
                      </v-menu>
                      <small
                        v-show="form.errors.has('first_connection_fee_on')"
                        class="validation-error"
                      >{{
                        form.errors.get('first_connection_fee_on')
                      }}</small>
                    </v-col>
                  </div>
                </v-expand-transition>
                <v-col
                  cols="10"
                  class="mt-4"
                >
                  <label style="font-size: 16px">Communication channels</label>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="form.communication_channels"
                        label="SMS"
                        value="1"
                        :rules="communicationChannelRules"
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="form.communication_channels"
                        label="Email"
                        value="2"
                        :rules="communicationChannelRules"
                        :disabled="!emailIsSet"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'
import { serialize } from 'object-to-formdata'
import axios from 'axios'
import moment from 'moment'
import getMeters from '@/mixins/getMeters'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [getMeters, validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      name: '',
      email: '',
      phone: '',
      meter_id: '',
      meter_type_id: '',
      account_number: '',
      first_connection_fee_on: moment().format('YYYY-MM-DD'),
      should_pay_connection_fee: 0,
      use_custom_charges_for_cost_per_unit: 0,
      cost_per_unit: '',
      communication_channels: ['1'],
      debt: 0,
      credit: 0,
      connection_fee: '',
      number_of_months_to_pay_connection_fee: '',
      use_custom_charges_for_service_charge: 0,
      service_charge: '',
    }),
    action: 'Save',
    meters_search: null,
    isInitialMeterSelect: false,
    month_menu: false,
  }),
  computed: {
    ...mapGetters(['selectedStation']),
    meters_url() {
      return `available-meters?station_id=${this.selectedStation}`
    },
    communicationChannelRules() {
      return [
        this.form.communication_channels.length > 0 || 'At least one communication channel should be selected',
      ]
    },
    emailIsSet() {
      const hasValue = this.form.email && this.form.email.length > 0
      const hasValidationError = this.$refs.email && this.$refs.email.hasError

      return hasValue && (!hasValidationError || hasValidationError === 'undefined')
    },
  },
  watch: {
    user() {
      if (Object.keys(this.user).length > 0) {
        this.form.update(this.user)
        const debt = this.$options.filters.formatTotalUserDebt(this.user.account_balance, this.user.unaccounted_debt)
        const credit = this.$options.filters.formatTotalUserCredit(this.user.account_balance)
        this.form.debt = debt !== 0 ? parseFloat(debt.replace(/,/g, '')) : debt
        this.form.credit = credit !== 0.0 ? parseFloat(credit.replace(/,/g, '')) : credit
        this.getUserMeter(this.form.meter_id)
        this.action = 'Update'
      } else {
        this.form.meter_type_id = ''
        this.form.meter_id = ''
        this.form.use_custom_charges_for_cost_per_unit = 0
        this.form.should_pay_connection_fee = 0
        this.form.communication_channels = ['1']
        this.form.credit = 0
        this.form.debt = 0
        this.form.use_custom_charges_for_service_charge = 0
        this.action = 'Save'
      }
    },
    meters_search(value) {
      if (!this.isInitialMeterSelect) {
        this.getMeters(value)
      }
      this.isInitialMeterSelect = false
    },
    showDialog(value) {
      if ((value && this.meters.length === 0) && this.action === 'Save') {
        this.getMeters('')
      }
    },
  },
  methods: {
    closeDialog() {
      this.$refs.form.reset()
      this.$emit('close')
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        let appendUrl
        if (this.action === 'Save') {
          appendUrl = ''
        } else {
          appendUrl = `/${this.form.id}?_method=PUT`
        }
        this.form
          .submit('post', `users${appendUrl}`, {
          // Transform form objects to FormData
            transformRequest: [
            // eslint-disable-next-line no-unused-vars
              function (data, headers) {
                const { communication_channels } = data
                delete data.communication_channels
                const serializedData = serialize(data)
                serializedData.append('communication_channels', JSON.stringify(communication_channels))

                return serializedData
              },
            ],
          })
          .then(response => {
            const action = this.action === 'Save' ? 'saved' : 'updated'
            this.$notification.success(`Customer ${action} successfully`)
            const { credit } = this.form
            if (this.action === 'Save' && credit > 0) {
              const firstName = response.data.name.split(' ')[0]
              setTimeout(() => { this.$notification.success(`Credit of Ksh ${credit} added to ${firstName}'s account will be processed shortly.`) }, 1500)
              setTimeout(() => this.$emit('user-added'), 3000)
            }
            this.$emit('user-added')
            this.closeDialog()
            this.getMeters()
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
    getUserMeter(meterId) {
      this.isMetersLoading = true
      axios
        .get(`meters/${meterId}`)
        .then(response => {
          this.meters = [response.data]
          this.form.meter_id = response.data.id
          this.form.meter_type_id = response.data.type_id
          this.isMetersLoading = false
          this.isInitialMeterSelect = true
        })
        .catch(error => {
          this.isMetersLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    onSelectedMeterChange(value) {
      this.form.meter_id = value.id
      this.form.meter_type_id = value.type_id
    },
  },
}
</script>
